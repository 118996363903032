import {combineReducers} from "redux"
import menu, * as fromMenu from "../../shared-components/store/temporaries/menu/menuReducer"
import page, * as fromPage from "../../shared-components/store/temporaries/page/pageReducer"
import table, * as fromTable from "../../shared-components/store/temporaries/table/tableReducer"
import * as fromResetPassword from "../../shared-components/store/temporaries/resetPassword/resetPasswordReducer"
import stepBar, * as fromStepBar from "../../shared-components/store/temporaries/stepBar/stepBarReducer"
import tabBar, * as fromTabBar from "../../shared-components/store/temporaries/tabBar/tabBarReducer"
import validateRefund, * as fromValidateRefund from "../temporaries/validateRefund/validateRefundReducer"
import bankAccount, * as fromBankAccount from "../temporaries/bankAccounts/bankAccountsReducer"
import bank, * as fromBank from "../temporaries/banks/banksReducer"
import search, * as fromSearch from "../../shared-components/store/temporaries/search/searchReducer"
import legalForms, * as fromLegalForms from "../../shared-components/store/temporaries/legalForms/legalFormsReducer"
import * as fromHandOverCheckbooks from "../temporaries/handOverCheckbooks/handOverCheckbooksReducer"
import pagination, * as fromPagination from "../../shared-components/store/temporaries/pagination/paginationReducer"
import detailsRefund, * as fromDetailsRefund from "../temporaries/detailsRefund/detailsRefundReducer"
import detailsTransfer, * as fromDetailsTransfer from "../temporaries/detailsTransfer/detailsTransferReducer"
import detailsPayment, * as fromDetailsPayment from "../temporaries/detailsPayment/detailsPaymentReducer"

/**
 * Part of the store to stock temporary data.
 */
const temporaries = combineReducers({
    bank,
    bankAccount,
    detailsPayment,
    detailsRefund,
    detailsTransfer,
    handOverCheckbooks: fromHandOverCheckbooks.handOverCheckbooks(),
    legalForms,
    menu,
    page,
    pagination,
    resetPassword: fromResetPassword.resetPassword(),
    search,
    stepBar,
    tabBar,
    table,
    validateRefund,
})

export default temporaries

// LEGAL FORMS OPERATIONS

export const getLegalForms = state => fromLegalForms.getLegalForms(state.legalForms)

// MENU OPERATIONS
/**
 * Verify is the menu is responsive
 */
export const isMenuResponsive = state => fromMenu.isResponsive(state.menu)

// PAGE OPERATIONS
/**
 * Return the actual page.
 */
export const getActualPage = state => fromPage.getActualPage(state.page)
/**
 * Return the previous page.
 */
export const getPreviousPage = state => fromPage.getPreviousPage(state.page)

// RESET PASSWORD OPERATIONS
/**
 * Tell if we should display a success message after resetting password
 */
export const getResetPasswordDisplaySuccessMessage = state =>
    fromResetPassword.getResetPasswordDisplaySuccessMessage(state.resetPassword)

export const getPasswordScore = state => fromResetPassword.getPasswordScore(state.resetPassword)

// TABLE OPERATIONS
/**
 * Return the table data (ids).
 * @param tableName the name of the table
 */
export const getTableDataIds = (state, tableName) => fromTable.getIds(state.table, tableName)
/**
 * Return the ids of the selected lines in a table.
 * @param tableName the name of the table
 */
export const getTableSelectedLinesIds = (state, tableName) => fromTable.getSelectedLines(state.table, tableName)
/**
 * Return the ids of the activated lines in a table.
 * @param tableName the name of the table
 */
export const getTableActivatedLinesIds = (state, tableName) => fromTable.getActivatedLines(state.table, tableName)

/**
 * Return if line ID is activated
 * @param tableName the name of the table
 * @param id the line id to test
 */
export const getTableIsLineIdActivated = (state, tableName, id) => fromTable.isLineActivated(state.table, tableName, id)

/**
 * Return the id of the line in edition in this table or null if there
 * is no line in edition.
 * @param tableName the name of the table
 */
export const getTableLineInEditionId = (state, tableName) => fromTable.getLineInEdition(state.table, tableName)

// PAGINATION OPERATIONS

/*
 * Return the active page for component
 */
export const getActivePage = (state, componentName) => fromPagination.getActivePage(state.pagination, componentName)
/**
 * Return the max page for a component
 */
export const getMaxPage = (state, componentName) => fromPagination.getMaxPage(state.pagination, componentName)

/**
 * Return the number of total results
 */
export const getTotalResults = (state, componentName) => fromPagination.getTotalResults(state.pagination, componentName)

/**
 * Return simple checkbooks
 */
export const getSimpleCheckbooks = state => fromHandOverCheckbooks.getSimpleCheckbooks(state.handOverCheckbooks)

/**
 * Return barCode checkbooks
 */
export const getBarCodeCheckbooks = state => fromHandOverCheckbooks.getBarCodeCheckbooks(state.handOverCheckbooks)

// SEARCH OPERATIONS
/**
 * Return the results of the town search found in the state.
 */
export const getSearchTownResults = (state, name) => fromSearch.getTownResults(state.search, name)
/**
 * Return the query of the town search found in the state.
 */
export const getSearchTownQuery = (state, name) => fromSearch.getTownQuery(state.search, name)

// STEP BAR OPERATIONS
/**
 * Return the actual step selected in the step bar.
 */
export const getStepBarActualStep = state => fromStepBar.getActualStep(state.stepBar)
/**
 * Return if the steps are clickable
 */
export const isStepsClickable = state => fromStepBar.isStepsClickable(state.stepBar)

// TAB BAR OPERATIONS
/**
 * Return the actual tab selected in the tab bar.
 */
export const getTabBarActualTab = state => fromTabBar.getActualTab(state.tabBar)

// VALIDATE REFUND OPERATIONS
export const getValidateRefundPreviousPage = state => fromValidateRefund.getPreviousPage(state.validateRefund)

// BANK OPERATIONS
export const getBank = state => fromBank.getBank(state.bank)

// BANK ACCOUNT OPERATIONS
export const getBankAccount = state => fromBankAccount.getBankAccount(state.bankAccount)

// DETAILS REFUND OPERATION
export const getDetailsRefundId = state => fromDetailsRefund.getId(state.detailsRefund)

// DETAILS PAYMENT OPERATION
export const getDetailsPaymentId = state => fromDetailsPayment.getId(state.detailsPayment)

// DETAILS TRANSFER OPERATION
export const getDetailsTransferId = state => fromDetailsTransfer.getId(state.detailsTransfer)
