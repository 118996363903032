import {combineReducers} from "redux"
import style, * as fromStyle from "../../shared-components/store/localStorage/style/styleReducer"
import texts, * as fromTexts from "../../shared-components/store/localStorage/texts/textsReducer"
import dynamicConfiguration, * as fromDynConf from "../../shared-components/store/localStorage/dynamicConfiguration/dynamicConfigurationReducer"
import registrationState, * as fromRegistrationState from "./registration/registrationReducer"
import cookies, * as fromCookies from "../../shared-components/store/localStorage/cookies/cookiesReducer"

/**
 * Part of the store to stock data which will be saved in local storage.
 */
const localStorage = combineReducers({
    cookies,
    dynamicConfiguration,
    registrationState,
    style,
    texts,
})

export default localStorage

// DYNAMIC CONFIGURATION OPERATIONS
/**
 * Return the value associated with the key if it is found in the state,
 * undefined otherwise.
 */
export const getDynamicConfigurationValue = (state, key) => fromDynConf.getValue(state.dynamicConfiguration, key)

// STYLE OPERATIONS
/**
 * Get the style from the store.
 */
export const getStyle = (state, contractId) => fromStyle.getStyle(state.style, contractId)
/**
 * Is style customized ?
 */
export const isCustomizedStyle = (state, contractId) => fromStyle.isCustomized(state.style, contractId)

// REGISTRATION OPERATIONS
/**
 * Get intervenant type from pre registration scene from the store.
 * @param state
 */
export const getPreRegistrationIntervenantType = state =>
    fromRegistrationState.getPreRegistrationIntervenantType(state.registrationState)

/**
 * Get intervenant pre registration informations from the store.
 * @param state
 */
export const getPreRegistrationInformations = state =>
    fromRegistrationState.getPreRegistrationState(state.registrationState)

/**
 * Get intervenant main informations from the store.
 * @param state
 */
export const getRegistrationMainInformations = state =>
    fromRegistrationState.getRegistrationMainInformationsState(state.registrationState)

/**
 * Return registration main informations for overview scene of Physical Intervenant
 * @param state
 */
export const getOverviewPhysicalIntervenantMainInformations = state =>
    fromRegistrationState.getOverviewPhysicalIntervenantMainInformationsState(state.registrationState)

/**
 * Return registration main informations for overview scene of Moral Intervenant
 * @param state
 */
export const getOverviewMoralIntervenantMainInformations = state =>
    fromRegistrationState.getOverviewMoralIntervenantMainInformationsState(state.registrationState)

/**
 * Get intervenant refund informations from the store.
 * @param state
 */
export const getRegistrationRefundInformations = state =>
    fromRegistrationState.getRegistrationRefundInformationsState(state.registrationState)

// TEXTS OPERATIONS
/**
 * Get the texts from the store.
 */
export const getTexts = (state, contractId) => fromTexts.getTexts(state.texts, contractId)
/**
 * Are texts customized ?
 */
export const areCustomizedTexts = (state, contractId) => fromTexts.areCustomized(state.texts, contractId)

// COOKIES OPERATIONS
/**
 * Get cookies
 */
export const getCookiesChoices = state => fromCookies.getCookiesChoices(state.cookies)
