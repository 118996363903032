import {SET_PREVIOUS_PAGE} from "../../../actions/temporaries/validateRefund/validateRefundActionTypes"
/**
 * Validate refund scene reducer
 */
const validateRefund = (state = {}, action = {}) => {
    switch (action.type) {
        case SET_PREVIOUS_PAGE:
            return {
                ...state,
                previousPage: action.page,
            }
        default:
            return state
    }
}

export default validateRefund

/**
 * Return the previous page of this scene
 */
export const getPreviousPage = state => state.previousPage
