import {REQUEST, REQUEST_SUCCESS, REQUEST_FAILURE} from "../../../shared-components/store/requests/requestsReducer"
import {RequestInfo, RequestKeys} from "../../../shared-components/constants/requests"

const bank = (state = {}, action = {}) => {
    switch (action.type) {
        case REQUEST_SUCCESS:
            if (action.requestKeyName === RequestInfo[RequestKeys.RETRIEVE_BANK].name) {
                return action.bank
            }
            return state
        case REQUEST_FAILURE:
        case REQUEST:
            if (action.requestKeyName === RequestInfo[RequestKeys.RETRIEVE_BANK].name) {
                return {}
            }
            return state
        default:
            return state
    }
}

export default bank

export const getBank = state => state
