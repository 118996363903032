import {
    SET_ID_DETAILS_TRANSFER,
    CLEAR_DETAILS_TRANSFER_ID,
} from "../../../actions/temporaries/detailsTransfer/detailsTransferActionTypes"

const detailsTransfer = (state = {}, action = {}) => {
    switch (action.type) {
        case SET_ID_DETAILS_TRANSFER:
            return {
                id: action.id,
            }
        case CLEAR_DETAILS_TRANSFER_ID:
            return {}
        default:
            return state
    }
}

export default detailsTransfer

export const getId = state => state.id
