/**
 * The pages of the application.
 */
export const Page = {
    BARCODE_REFUND: "BARCODE_REFUND",
    CONFIRM_BARCODE_REFUND: "CONFIRM_BARCODE_REFUND",
    CONFIRM_SIMPLE_REFUND: "CONFIRM_SIMPLE_REFUND",
    FILE_REFUND: "FILE_REFUND",
    HISTORY_REFUND: "HISTORY_REFUND",
    HOME: "HOME",
    LEGAL_NOTICE: "LEGAL_NOTICE",
    LOGIN: "LOGIN",
    SIMPLE_REFUND: "SIMPLE_REFUND",
    NOT_FOUND: "NOT_FOUND",
    PAYMENT_HISTORY: "PAYMENT_HISTORY",
    PROFILE_AUTHENTICATION_INFORMATIONS: "PROFILE_AUTHENTICATION_INFORMATIONS",
    PROFILE_MAIN_INFORMATIONS: "PROFILE_MAIN_INFORMATIONS",
    PROFILE_REFUND_INFORMATIONS: "PROFILE_REFUND_INFORMATIONS",
    REGISTRATION: "REGISTRATION",
    REGISTRATION_CONFIRMATION: "REGISTRATION_CONFIRMATION",
    REGISTRATION_MAIN_INFORMATIONS: "REGISTRATION_MAIN_INFORMATIONS",
    REGISTRATION_OVERVIEW_INFORMATIONS: "REGISTRATION_OVERVIEW_INFORMATIONS",
    REGISTRATION_REFUND_INFORMATIONS: "REGISTRATION_REFUND_INFORMATIONS",
    RGPD: "RGPD",
    RESET_PASSWORD: "RESET_PASSWORD",
    RECOVER_LOGIN_PASSWORD: "RECOVER_LOGIN_PASSWORD",
    TECHNICAL_ERROR: "TECHNICAL_ERROR",
    TRANSFER_HISTORY: "TRANSFER_HISTORY",
    UNAUTHORIZED: "UNAUTHORIZED",
    VALIDATE_BARCODE_REFUND: "VALIDATE_BARCODE_REFUND",
    VALIDATE_SIMPLE_REFUND: "VALIDATE_SIMPLE_REFUND",
}
