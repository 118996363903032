import {
    CLEAR_REGISTRATION_MAIN_INFORMATIONS,
    SAVE_REGISTRATION_MAIN_INFORMATIONS
} from "../../../../actions/localStorage/registrationMainInformations/registrationMainInformationsActionTypes"
import {CLEAR_INTERVENANT_REGISTRATION_INFORMATIONS} from "../../../../shared-components/actions/localStorage/intervenantRegistration/intervenantRegistrationActionTypes"

/**
 * Part of the state for registration main information process
 * @param state
 * @param action
 * @returns {{}}
 */
const mainInformationsState = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_REGISTRATION_MAIN_INFORMATIONS:
            return action.registrationMainInformations
        case CLEAR_INTERVENANT_REGISTRATION_INFORMATIONS:
        case CLEAR_REGISTRATION_MAIN_INFORMATIONS:
            return {}
        default:
            return state
    }
}

export default mainInformationsState

/**
 * Return registration main informations
 * @param state
 */
export const getMainInformationsState = state => state


/**
 * Return registration main informations for overview scene of Physical Intervenant
 * @param state
 */
export const getOverviewPhysicalIntervenantMainInformationsState = state => ({
    addressComplement: state.addressComplement,
    civility: state.civility,
    email: state.contactMail,
    firstName: state.firstName,
    kindWayAddress: state.kindWayAddress,
    locality: state.locality,
    name: state.name,
    numberAndStreetName: state.numberAndStreetName,
    numberStreet: state.numberStreet,
    postalCodeTown: state.postalCodeTown,
    principalPhoneNumber: state.principalPhoneNumber,
    streetName: state.streetName,
    typeWayAddress: state.typeWayAddress,
})

/**
 * Return registration main informations for overview scene of Moral Intervenant
 * @param state
 */
export const getOverviewMoralIntervenantMainInformationsState = state => ({
    addressComplement: state.addressComplement,
    email: state.contactMail,
    locality: state.locality,
    numberAndStreetName: state.numberAndStreetName,
    postalCodeTown: state.postalCodeTown,
    principalPhoneNumber: state.principalPhoneNumber,
    socialReason: state.socialReason,
})
