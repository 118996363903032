import {combineReducers} from "redux"
import {
    HANDOVER_CHECKBOOK_SUCCESS,
    REMOVE_CHECKBOOK,
    CLEAR_CHECKBOOKS,
} from "../../../shared-components/actions/temporaries/handOverCheckbooks/handOverCheckbooksActionTypes"
import {omit} from "lodash"

export const handOverCheckbooks = (state = {}, action = {}) => {
    const simpleCheckbooks = (state = {}, action = {}) => {
        switch (action.type) {
            case HANDOVER_CHECKBOOK_SUCCESS:
                if (action.checkbookType === "SIMPLE") {
                    return {
                        ...state,
                        [action.checkbook.id]: {
                            ...action.checkbook,
                        },
                    }
                } else return state
            case REMOVE_CHECKBOOK:
                return omit(state, action.id)
            case CLEAR_CHECKBOOKS:
                return {}
            default:
                return state
        }
    }

    const barCodeCheckbooks = (state = {}, action = {}) => {
        switch (action.type) {
            case HANDOVER_CHECKBOOK_SUCCESS:
                if (action.checkbookType === "BARCODE") {
                    return {
                        ...state,
                        [action.checkbook.barCode]: {
                            ...action.checkbook,
                        },
                    }
                } else if (action.checkbookType === "BARCODE_LIST") {
                    return {
                        ...state,
                        ...action.checkbooks,
                    }
                } else return state
            case REMOVE_CHECKBOOK:
                return omit(state, action.id)
            case CLEAR_CHECKBOOKS:
                return {}
            default:
                return state
        }
    }

    return combineReducers({
        simpleCheckbooks,
        barCodeCheckbooks,
    })
}

export const getSimpleCheckbooks = state => state.simpleCheckbooks
export const getBarCodeCheckbooks = state => state.barCodeCheckbooks
