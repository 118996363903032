import React from "react"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {Page} from "../constants/page/pages"
import {AREA_TYPE} from "../constants/areaType"
import history from "../history"
import {
    getResetPasswordScore,
    getResetPasswordDisplaySuccessMessage,
} from "../store/selectors/temporaries/resetPasswordSelectors"
import {
    isFetchingChangePassword,
    getChangePasswordErrorMessage,
} from "../store/selectors/temporaries/changePasswordSelectors"
import {INTERVENANT_AREA} from "../shared-components/constants/areaTypes"
import {INTERVENANT_AREA_URL} from "../shared-components/constants/links"
import {RequestInfo} from "../shared-components/constants/requests"
import {CommonRequestKeys} from "../shared-components/common/constants/requests"

/**
 * Note: A catch has been added to manage the following use case:
 * 1. The user is on the app
 * 2. We deliver a new version
 * 3. Without reloading, the user try to navigate to a page not yet in its cache
 * 4. The app will try to load the old chunk
 * 5. If the chunk is not present, blank page
 *
 * That's why we have added an auto reload
 */

// // ASYNC LAYOUTS
const BreadcrumbLayout = React.lazy(() => import("./breadcrumbLayout").catch(() => window.location.reload()))

export const AsyncBreadcrumbLayout = props => (
    <BreadcrumbLayout isUserConnected={props.isUserConnected} pageName={props.actualPage} />
)

const RegistrationLayout = React.lazy(() => import("./registrationLayout").catch(() => window.location.reload()))

export const AsyncRegistrationLayout = props => (
    <RegistrationLayout isUserConnected={props.isUserConnected} pageName={props.actualPage} />
)

const ProfileLayout = React.lazy(() => import("./profileLayout").catch(() => window.location.reload()))

export const AsyncProfileLayout = props => (
    <ProfileLayout isUserConnected={props.isUserConnected} pageName={props.actualPage} />
)

// ASYNC SCENES
const UnauthorizedScene = React.lazy(() =>
    import("../shared-components/scenes/Unauthorized/unauthorizedContainer").catch(() => window.location.reload()),
)

export const AsyncUnauthorizedScene = () => (
    <UnauthorizedScene
        onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)}
        unauthorizedPage={Page.UNAUTHORIZED}
    />
)

const HomeScene = React.lazy(() => import("../scenes/Home/homeContainer").catch(() => window.location.reload()))

export const AsyncHomeScene = () => <HomeScene />

const FileRefundScene = React.lazy(() =>
    import("../scenes/FileRefund/fileRefundContainer").catch(() => window.location.reload()),
)

export const AsyncFileRefundScene = () => <FileRefundScene />

const NotFoundScene = React.lazy(() =>
    import("../shared-components/scenes/NotFound/notFoundContainer").catch(() => window.location.reload()),
)

export const AsyncNotFoundScene = () => (
    <NotFoundScene notFoundPage={Page.NOT_FOUND} onHomeClick={() => history.push(PagesArchitecture[Page.HOME].route)} />
)

const TechnicalErrorScene = React.lazy(() =>
    import("../shared-components/components/ErrorBoundary/errorBoundary").catch(() => window.location.reload()),
)

export const AsyncTechnicalErrorScene = () => (
    <TechnicalErrorScene
        page={Page.TECHNICAL_ERROR}
        redirectToHomePage={() => history.push(PagesArchitecture[Page.HOME].route)}
    />
)

const LegalNoticeScene = React.lazy(() =>
    import("../shared-components/scenes/LegalNotice/legalNoticeContainer").catch(() => window.location.reload()),
)

export const AsyncLegalNoticeScene = () => (
    <LegalNoticeScene areaType={AREA_TYPE} legalNoticePage={Page.LEGAL_NOTICE} urlDomiserve={INTERVENANT_AREA_URL} />
)

const RgpdScene = React.lazy(() => import("../scenes/Rgpd/rgpdContainer").catch(() => window.location.reload()))

export const AsyncRgpdScene = () => <RgpdScene areaType={INTERVENANT_AREA} />

const RegistrationScene = React.lazy(() =>
    import("../scenes/Registration/registrationContainer").catch(() => window.location.reload()),
)

export const AsyncRegistrationScene = () => <RegistrationScene />

const RegistrationConfirmationScene = React.lazy(() =>
    import("../scenes/Registration/Confirmation/registrationConfirmationContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncRegistrationConfirmationScene = () => <RegistrationConfirmationScene />

const RegistrationMainInformationsScene = React.lazy(() =>
    import("../scenes/Registration/MainInformations/registrationMainInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncRegistrationMainInformationsScene = () => <RegistrationMainInformationsScene />

const RegistrationOverviewInformationsScene = React.lazy(() =>
    import("../scenes/Registration/OverviewInformations/registrationOverviewInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncRegistrationOverviewInformationsScene = () => <RegistrationOverviewInformationsScene />

const RegistrationRefundInformationsScene = React.lazy(() =>
    import("../scenes/Registration/RefundInformations/registrationRefundInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncRegistrationRefundInformationsScene = () => <RegistrationRefundInformationsScene />

const ResetPasswordScene = React.lazy(() =>
    import("../shared-components/scenes/ResetPassword/resetPasswordContainer").catch(() => window.location.reload()),
)

export const AsyncResetPasswordScene = () => (
    <ResetPasswordScene
        areaType={AREA_TYPE}
        errorMessage={getChangePasswordErrorMessage}
        getResetPasswordDisplaySuccessMessage={getResetPasswordDisplaySuccessMessage}
        getResetPasswordScore={getResetPasswordScore}
        isFetching={isFetchingChangePassword}
        loginRoute={PagesArchitecture[Page.LOGIN].route}
        passwordRequestKeyName={RequestInfo[CommonRequestKeys.CHANGE_PASSWORD].name}
        resetPasswordPage={Page.RESET_PASSWORD}
    />
)

const SimpleRefundScene = React.lazy(() =>
    import("../scenes/SimpleRefund/simpleRefundContainer").catch(() => window.location.reload()),
)

export const AsyncSimpleRefundScene = () => <SimpleRefundScene />

const BarcodeRefundScene = React.lazy(() =>
    import("../scenes/BarcodeRefund/barcodeRefundContainer").catch(() => window.location.reload()),
)

export const AsyncBarcodeRefundScene = () => <BarcodeRefundScene />

const ValidateRefundScene = React.lazy(() =>
    import("../scenes/ValidateRefund/validateRefundContainer").catch(() => window.location.reload()),
)

export const AsyncValidateRefundScene = () => <ValidateRefundScene />

const ConfirmRefundScene = React.lazy(() =>
    import("../scenes/ConfirmRefund/confirmRefundContainer").catch(() => window.location.reload()),
)

export const AsyncConfirmRefundScene = () => <ConfirmRefundScene />

const HistoryRefundScene = React.lazy(() =>
    import("../scenes/HistoryRefund/historyRefundContainer").catch(() => window.location.reload()),
)

export const AsyncHistoryRefundScene = () => <HistoryRefundScene />

const PaymentHistoryScene = React.lazy(() =>
    import("../scenes/PaymentHistory/paymentHistoryContainer").catch(() => window.location.reload()),
)

export const AsyncPaymentHistoryScene = () => <PaymentHistoryScene />

const TransferHistoryScene = React.lazy(() =>
    import("../scenes/TransferHistory/transferHistoryContainer").catch(() => window.location.reload()),
)

export const AsyncTransferHistoryScene = () => <TransferHistoryScene />

const ProfileMainInformationsScene = React.lazy(() =>
    import("../scenes/Profile/MainInformations/profileMainInformationsContainer").catch(() => window.location.reload()),
)

export const AsyncProfileMainInformationsScene = () => <ProfileMainInformationsScene />

const RefundMainInformationsScene = React.lazy(() =>
    import("../scenes/Profile/RefundInformations/profileRefundInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncRefundMainInformationsScene = () => <RefundMainInformationsScene />

const AuthenticationMainInformationsScene = React.lazy(() =>
    import("../scenes/Profile/AuthenticationInformations/profileAuthenticationInformationsContainer").catch(() =>
        window.location.reload(),
    ),
)

export const AsyncAuthenticationMainInformationsScene = () => <AuthenticationMainInformationsScene />
