import * as fromSessionStorage from "../../sessionStorage/sessionStorageReducer"
import * as fromRequests from "../../../shared-components/store/requests/requestsReducer"
import {RequestKeys, RequestInfo} from "../../../shared-components/constants/requests"
import {IntervenantType} from "../../../shared-components/constants/intervenantType"

/**
 * Get the user token from the state.
 */
export const getUserToken = state => fromSessionStorage.getUserToken(state.sessionStorage)

/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromSessionStorage.getExpirationDate(state.sessionStorage)

/**
 * Verify is user is connected.
 */
export const isUserConnected = state => !!getUserToken(state)

/**
 * Verify if user token is fetching.
 */
export const isFetchingAuthority = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.USER_AUTHORITY].name, state.requests)

/**
 * Get the authority error message from the state.
 */
export const getAuthorityErrorMessage = state =>
    fromRequests.getErrorMessage(RequestInfo[RequestKeys.USER_AUTHORITY].name, state.requests)

/**
 * Get user permissions from the store.
 */
export const getUserPermissions = state => fromSessionStorage.getUserPermissions(state.sessionStorage)

/**
 * Get user identity from the store.
 */
export const getUserDetails = state => fromSessionStorage.getUserDetails(state.sessionStorage)

/**
 * Get user client type from the store.
 */
export const getUserClientType = state => getUserDetails(state).clientType

/**
 * Get user intervenant from the store.
 */
export const getUserIntervenant = state => getUserDetails(state).intervenant

/**
 * Get user intervenant id from the store.
 */
export const getUserIntervenantId = state => (getUserIntervenant(state) ? getUserIntervenant(state).id : null)

/**
 * Get user intervenant type from the store.
 */
export const getUserIntervenantType = state => (getUserIntervenant(state) ? getUserIntervenant(state).type : "")
/**
 * Get user intervenant status from the store.
 */
export const getUserIntervenantStatus = state =>
    getUserIntervenant(state) ? getUserIntervenant(state).contactStatus : ""

/**
 * Get user client ID from the store.
 */
export const getUserClientId = state => getUserDetails(state).clientId

/**
 * Get user login from the store.
 */
export const getUserLogin = state => getUserDetails(state).login

/**
 * Get user siret from the store.
 */
export const getUserSiret = state => getUserDetails(state).siret

/**
 * Get user full name
 */
export const getUserFullname = state => {
    const userDetails = getUserDetails(state)

    if (userDetails.intervenant) {
        // Intervenant type is use is espace-intervenant
        switch (userDetails.intervenant.type) {
            case IntervenantType.INTERVENANT_DIRECT:
            case IntervenantType.PROSPECT_PHYSIQUE:
                return userDetails.firstName + " " + userDetails.lastName
            case IntervenantType.MANDATAIRE:
            case IntervenantType.PRESTATAIRE:
            case IntervenantType.PROSPECT_MORAL:
                return userDetails.socialReason
            default:
                return ""
        }
    }

    return ""
}

/**
 * Verify if user details is fetching ?
 */
export const isFetchingUserDetails = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.USER_INFO].name, state.requests)

/**
 * Verifiy is recover login password request is fetching
 */
export const isFetchingRecoverLoginPassword = state =>
    fromRequests.isFetching(RequestInfo[RequestKeys.RECOVER_LOGIN_PASSWORD].name, state.requests)
