/**
 * Factory method to create a menu item
 * @param actualPage page where the user is
 * @param history    history function for navigation
 * @param features   enabled application features
 * @param page       page where the item should redirect
 * @param pagesArchitecture the application pages architecture
 * @param text       override the text
 * @param onClick    override the onClick function
 * @param isFeatureEnabled    override isFeatureEnabled
 */
export const createItem = (
    actualPage,
    history,
    features,
    page,
    pagesArchitecture,
    text,
    onClick,
    isFeatureEnabled,
) => ({
    type: "ITEM",
    text: text ? text : pagesArchitecture[page].breadcrumbText,
    active: actualPage === page,
    onClick: onClick ? () => onClick() : () => history.push(pagesArchitecture[page].route),
    userConnected: pagesArchitecture[page].userConnected,
    permissions: pagesArchitecture[page].permissions,
    isFeatureEnabled: isFeatureEnabled
        ? isFeatureEnabled
        : !pagesArchitecture[page].featureFlag ||
          (pagesArchitecture[page].featureFlag && features.includes(pagesArchitecture[page].featureFlag)),
    feature: pagesArchitecture[page].featureFlag,
})

/**
 * Factory method to create a menu item that opens a new web page.
 * @param actualPage page where the user is
 * @param history    history function for navigation
 * @param features   enabled application features
 * @param page       page where the item should redirect
 * @param pagesArchitecture the application pages architecture
 * @param text       override the text
 * @param isFeatureEnabled    override isFeatureEnabled
 */
export const createLink = (
    actualPage,
    history,
    features,
    page,
    pagesArchitecture,
    text,
    isFeatureEnabled,
) => {
    // Fetch configuration for the page from pagesArchitecture
    const pageConfig = pagesArchitecture[page];

    // Prepare the onClick handler that opens the link in a new tab
    const handleLinkClick = (event) => {
        window.open(pageConfig.route, '_blank', 'noopener,noreferrer');
    };

    return {
        type: "ITEM",
        text: text || pageConfig.breadcrumbText,  // Use custom text or fallback to breadcrumb text
        active: actualPage === page,
        onClick: handleLinkClick,  // Use the custom handleLinkClick function
        userConnected: pageConfig.userConnected,
        permissions: pageConfig.permissions,
        isFeatureEnabled: !pageConfig.featureFlag || (pageConfig.featureFlag && features.includes(pageConfig.featureFlag)),
        feature: pageConfig.featureFlag,
    };
};

/**
 * Factory method to create a DropDown
 * @param dropdownText Text to show on the DropDown
 * @param isUserConnected show the drop down only when the user is connected
 * @param dropdownChoices all drop down choices
 * @param alignment LEFT or RIGHT
 */
export const createDropdown = (dropdownText, isUserConnected, dropdownChoices, alignment) => {
    return {
        type: "DROPDOWN",
        text: dropdownText,
        userConnected: isUserConnected,
        choices: dropdownChoices,
        alignment,
    }
}
