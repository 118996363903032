import {Page} from "../page/pages"
import {PagesArchitecture} from "../page/pagesArchitecture"
import {createDropdown, createItem} from "../../shared-components/common/utils/menu/menu"

/**
 * Return the menu
 * @param  {[type]} actualPage page where the user is
 * @param  {[type]} history    history function for navigation
 */
export const menuContent = (actualPage, history) => {
    return [
        createDropdown(
            "TITRES CESU",
            true,
            [
                createItem(actualPage, history, [], Page.SIMPLE_REFUND, PagesArchitecture),
                createItem(actualPage, history, [], Page.BARCODE_REFUND, PagesArchitecture),
                createItem(actualPage, history, [], Page.FILE_REFUND, PagesArchitecture),
                createItem(actualPage, history, [], Page.HISTORY_REFUND, PagesArchitecture),
            ],
            "RIGHT",
        ),
        createItem(actualPage, history, [], Page.PAYMENT_HISTORY, PagesArchitecture),
        createItem(actualPage, history, [], Page.TRANSFER_HISTORY, PagesArchitecture),
        createItem(actualPage, history, [], Page.LOGIN, PagesArchitecture, "CONNEXION"),
        createItem(actualPage, history, [], Page.REGISTRATION, PagesArchitecture, "INSCRIPTION"),
    ]
}
