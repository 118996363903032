import {
    GET_LEGAL_FORMS_FAILURE, GET_LEGAL_FORMS_REQUEST,
    GET_LEGAL_FORMS_SUCCESS
} from "../../../actions/temporaries/legalForms/legalFormsActionsTypes"

/**
 * Legal Forms reducer
 * Stock in store in temporaries list of legal forms
 *
 * @param state
 * @param action
 * @returns {Array}
 */
const legalForms = (state = [], action = {}) => {
    switch (action.type) {
        case GET_LEGAL_FORMS_SUCCESS:
            return action.response
        case GET_LEGAL_FORMS_REQUEST:
        case GET_LEGAL_FORMS_FAILURE:
            return []
        default:
            return state
    }
}

export default legalForms

/**
 * Get legal forms
 */
export const getLegalForms = state => state
