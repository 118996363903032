import {
    SET_ID_DETAILS_PAYMENT,
    CLEAR_DETAILS_PAYMENT_ID,
} from "../../../actions/temporaries/detailsPayment/detailsPaymentActionTypes"

const detailsPayment = (state = {}, action = {}) => {
    switch (action.type) {
        case SET_ID_DETAILS_PAYMENT:
            return {
                id: action.id,
            }
        case CLEAR_DETAILS_PAYMENT_ID:
            return {}
        default:
            return state
    }
}

export default detailsPayment

export const getId = state => state.id
