import {sharedGlobalTexts} from "../shared-components/styles/globalTexts"

export const defaultTexts = {
    ...sharedGlobalTexts,

    // Images
    logo: "",
    homeHeaderImage: "",
    homeFooterImage: "",
    checkbookTopRightCornerImage: "",
    checkbookNullIfDiscoveredImage: "",

    // BARCODE REFUND PAGE
    barcodeRefundSceneParagraph: "Pour déposer une remise de vos titres CESU Domiserve, scanner le code barre.",
    barCodeRefundSceneContainsInvalidCheckbook:
        "Votre remise comporte des titres non valides, veuillez les supprimer avant de passer à l'étape suivante",
    barCodeRefundSceneContainsNoCheckbook: "Aucun titre n’a été vérifié et validé",

    // HISTORY REFUND PAGE
    historyRefundTitle: "Retrouvez l'ensemble des remises de titres CESU que vous avez effectuées.",

    // HOME PAGE
    homeHeaderTitle: "Bienvenue sur votre Espace Intervenant Domiserve",
    homeHeaderParagraph1:
        "Vous avez choisi notre espace de remboursement en ligne et nous vous remercions de votre confiance.",
    homeHeaderParagraph2:
        "Cet outil vous permet:<br /><ul><li>D'effectuer vos dépôts de remise des chèques CESU</li><li>De consulter l'historique de vos dépôts et de vos remboursements</li><li>De consulter les paiements effectués via un compte CESU électronique</li></ul>",
    homeHeaderParagraph3:
        "Pour plus d'informations, veuillez nous contacter à l'aide du lien de contact situé en bas de cet écran.",

    manualRefundTileTitle: "Déposer une remise simple",
    manualRefundTile: "Saisissez quelques informations et recevez le virement sur votre compte bancaire",
    manualRefundUnauthorized:
        "Votre accès aux demandes de remboursement est désactivé, veuillez contacter notre service via le lien de contact en bas de page",

    paymentHistoryTileTitle: "Compte CESU",
    paymentHistoryTile: "Consulter les règlements effectués via un compte CESU électronique",

    // FILE REFUND PAGE
    fileRefundSceneParagraphLine1:
        "Pour déposer une remise de vos titres CESU Domiserve, charger votre fichier de remise.",
    fileRefundSceneParagraphLine2:
        "Pour rappel, seuls les fichiers de type .cesu peuvent être enregistrés sur le dépôt.",
    fileRefundSceneUploadSuccess:
        "Le fichier a bien été reçu par Domiserve. Vous pouvez vérifier le statut du traitement dans le tableau ci-dessous.",

    // LOGIN PAGE
    loginRegister:
        "Vous êtes intervenant(e) à domicile et souhaitez encaisser vos CESU Domiserve sur votre compte bancaire ?",

    codeNanPopup: "Saisissez votre code (7 chiffres), sans l'étoile ni la clé NAN",

    // PAYMENT HISTORY
    paymentHistoryTitle:
        "Retrouvez l'ensemble des paiements qui vous ont été effectués via un compte CESU électronique",

    // PROFILE CONNECTION INFORMATION
    profileChangingPasswordSuccess: "Votre mot de passe a été modifié avec succès.",

    // RESET PASSWORD PAGE
    resetPasswordTokenText: "Cette opération a déjà été réalisée. Lien expiré.",
    resetPasswordSuccessText: "Votre mot de passe a bien été changé. Vous allez être redirigé dans 5 secondes.",

    // SIMPLE REFUND PAGE
    simpleRefundSceneInfoParagraphLine1:
        "Pour déposer une remise de vos titres CESU Domiserve, renseignez le code mentionné sous la case à gratter.",
    simpleRefundSceneInfoParagraphLine2:
        "Attention une fois cette case découverte, le remboursement de vos titres se fera uniquement par les services de Domiserve.",
    simpleRefundSceneLabelValue: "Valeur (00.00) €",
    simpleRefundSceneLabelCode: "Code sous la case à gratter",
    simpleRefundSceneLabelNumber: "N° de chèque",
    simpleRefundSceneStepsParagraphTitle: "VOTRE REMISE DE TITRE CESU DOMISERVE EN 3 ETAPES :",
    simpleRefundSceneStepsParagraphLine1: "Pour chaque titre CESU, renseigner :",
    simpleRefundSceneStepsParagraphLine1a: "La valeur du titre",
    simpleRefundSceneStepsParagraphLine1b: "Le code mentionné sous la case à gratter",
    simpleRefundSceneStepsParagraphLine1c: "Le n° du chèque",
    simpleRefundSceneStepsParagraphLine2: "Cliquer sur “Ajouter votre titre CESU”",
    simpleRefundSceneStepsParagraphLine3: "Une fois tous vos titres saisis, cliquer sur “Valider la remise”",
    simpleRefundSceneAddButton: "Ajouter votre titre CESU",
    simpleRefundSceneTotalNumber: "NOMBRE TOTAL DE CHEQUES",
    simpleRefundSceneTotalAmount: "MONTANT TOTAL",
    simpleRefundSceneSubmitButton: "Valider la remise",
    simpleRefundSceneErrorNoCheckbooks: "Aucun titre n’a été vérifié et validé",

    // ERROR PAGE
    technicalErrorText: "Il semblerait qu'une erreur technique se soit produite.",

    // NOT FOUND PAGE
    // Texts
    notFoundText: "Page introuvable.",

    // PROFILE PAGE
    profileLayoutMessage: "Pour modifier les informations grisées, ",
    profileMainInfosUpdateSuccess: "Les modifications ont bien été prises en compte.",

    // PROFILE AND REGISTRATION PAGE
    mailOffersAcceptanceCheckboxMessage:
        "J’accepte de recevoir des offres commerciales, par voie électronique, de la part de Domiserve, des sociétés du Groupe auquel elle appartient, ses filiales, ses sous-traitants, partenaires et prestataires.",
    postOffersRefusalCheckboxMessage:
        "Je refuse de recevoir des offres commerciales de la part de Domiserve, des sociétés du Groupe auquel elle appartient, ses filiales, ses sous-traitants, partenaires et prestataires par courrier postal et via des appels téléphoniques.",

    // REGISTRATION PAGE
    registrationBirthdate: "Date de naissance *",
    registrationIntervenantInformation:
        "Pour vous inscrire à notre espace de remboursement en ligne, vous devez être affilié(e) au CRCESU.",
    registrationIntervenantInformationBubbleBeforeLink: "Pour vous affilier au CRCESU, rendez-vous sur le site ",
    registrationIntervenantInformationBubbleAfterLink:
        ", dès demain, vous pourrez activer votre espace de remboursement Domiserve.",
    registrationPhysicalIntervenantInformation: "Vous êtes déjà affilié(e) au CRCESU ?",
    registrationPhysicalIntervenantDesactivatedToggle:
        "Veuillez cliquer sur valider et remplir le formulaire d'affiliation.",
    registrationIntervenantNotFound: "Informations incorrectes.",
    registrationIntervenantAlreadyRegister:
        "Adhésion déjà effectuée. Pour recevoir vos identifiants de connexion, veuillez cliquer sur : ",
    registrationCRCESUWebServiceNotAvailable:
        "Pour des raisons techniques, l’affiliation au CRCESU est indisponible pour le moment. Veuillez réessayer ultérieurement…​",

    // REGISTRATION MAIN INFORMATIONS PAGE
    registrationMainInfosTitle: "Pour modifier les informations grisées, ",
    registrationMainInfosIdentityTitlePanel: "Identité",
    registrationMainInfosContactTitlePanel: "Contact",
    registrationMainInfosMainInterlocutorTitlePanel: "Interlocuteur principal",
    registrationMainInfosAddressTitlePanel: "Adresse",

    registrationLegalFormsErrorMessage: "Oups, une erreur est survenue lors de la récupération des formes juridique.",

    // REGISTRATION REFUND INFORMATIONS PAGE
    registrationRefundInfosPhysicalIntervenantTitle:
        "Les coordonnées bancaires sont au nom de l'intervenant salarié mentionné ci-avant, hors livret d'épargne (ex: livret A)",
    registrationRefundInfosMoralIntervenantTitle:
        "Attention, le titulaire du compte doit être identique à la raison sociale mentionnée ci-avant.",
    registrationRefundInfosDelayTitle: "Choix du délai de remboursement",
    registrationRefundInfosDelayText:
        "Les frais appliqués à vos remises par Internet dépendent de leurs montants et du délai de remboursement choisi. Pour vous aider dans votre choix, vous pouvez consulter la grille tarifaire en vigueur ",
    registrationRefundBankDomiciliationError:
        "Pour compléter vos coordonnées bancaires, veuillez vous rapprocher du CRCESU. Dans 48h, vous pourrez de nouveau vous inscrire sur votre espace intervenant.",

    // REGISTRATION OVERVIEW PAGE
    registrationOverviewServiceConditionsErrorMessage:
        "Veuillez accepter les conditions générales pour passer à l’étape suivante.",

    registrationOverviewTermsOfServiceText:
        "Les données à caractère personnel recueillies font l'objet d'un traitement dont le responsable est Domiserve conformément à la réglementation relative à la protection des données à caractère personnel. \n" +
        "Elles sont traitées pour utiliser le service de remboursement à distance des CESU Domiserve. Elles pourront éventuellement être traitées  pour une affiliation au CRCESU (Centre de Remboursement des CESU) qui subordonne l'adhésion au service de remboursement à distance des CESU Domiserve nécessaire pour encaisser les Chèques ou recevoir un virement par Compte CESU.\n" +
        "Les données sont traitées en vertu de l'exécution du contrat visant à l'utilisation du service de remboursement à distance des CESU Domiserve. Ces données seront conservées pendant 10 ans à compter de l'émission du CESU préfinancé.\n" +
        "Les données à caractère personnel collectées sont obligatoires afin de réaliser votre affiliation / un contrôle d'affiliation auprès du CRCESU et réaliser sur notre site vos demandes de remboursements en ligne de CESU Domiserve. A défaut, vous ne pourrez pas réaliser vos demandes remboursements depuis notre site. \n" +
        "Les données d'identification (code NAN) ont été collectées auprès du CRCESU. \n" +
        "Elles sont destinées à Domiserve et pourront être communiquées aux sociétés du Groupe auquel elle appartient et à ses sous-traitants ou partenaires pour les traitements et finalités cités ci-avant. Elles pourront également être communiquées à toute autorité administrative ou judiciaire habilitée ou plus généralement à tout tiers autorisé, pour satisfaire à ses obligations légales ou réglementaires. \n" +
        "Vous disposez d'un droit d'accès, de rectification, d'effacement, d'opposition et de limitation du traitement. Vous pouvez faire une demande de portabilité pour les données que vous nous avez fournies et qui sont nécessaires au contrat ou au traitement desquelles vous avez consenti. Vous pouvez à tout moment retirer votre consentement lorsque celui-ci a été préalablement donné. Vous pouvez aussi donner des instructions relatives à la conservation, à l'effacement et à la communication de vos données après votre décès. Ces droits peuvent être exercés, en précisant vos nom, prénom, adresse postale et en joignant une copie recto-verso de votre pièce d'identité, en vous adressant au responsable du traitement,  Domiserve - Direction des Opérations et des Systèmes d'Informations - 106 avenue Marx Dormoy - 92120 Montrouge.\n" +
        "Vous pouvez  vous adresser au Délégué à la Protection des Données de La Banque Postale - 115, rue de Sèvres - 75275 Paris Cedex 06.\n" +
        "En cas de difficulté en lien avec la gestion de vos données à caractère personnel, vous avez le droit d'introduire une réclamation auprès de la Commission Nationale de l'Informatique et des Libertés (CNIL). ",

    registrationOverviewConfirmationText: "Confirmez-vous ces informations ?",

    // REGISTRATION CONFIRMATION PAGE
    registrationConfirmationThanksText:
        "Vous avez choisi l’espace de remboursement en ligne pour vos CESU Domiserve et nous vous remercions de votre confiance.",
    registrationConfirmationEmailText: "Vos identifiants de connexion vous ont été envoyés par email à l’adresse : ",
    registrationConfirmationSafetyText:
        "Nous vous invitons à garder précieusement votre code NAN qui facilitera les échanges avec nos services.",
    registrationConfirmationSeeYouSoonText: "A très bientôt sur domiserve.com",

    // TRANSFER HISTORY PAGE
    transferHistoryTitle: "Retrouvez l'ensemble des virements qui vous ont été effectués.",

    // VALIDATE REFUND PAGE
    // Texts
    validateRefundParagraph:
        "Attention, une fois la case à gratter découverte, le remboursement de vos titres se fera uniquement par les services de Domiserve",

    // UNAUTHORIZED PAGE
    // Texts
    unauthorizedText: "Oups, vous vous êtes perdu ?",
}

let texts = {...defaultTexts}

export function updateGlobalTexts(newTexts) {
    texts = Object.assign(texts, newTexts)
}

export default texts
