import {
    CLEAR_INTERVENANT_REGISTRATION_INFORMATIONS,
    SAVE_INTERVENANT_PRE_REGISTRATION_INFORMATIONS,
    SAVE_INTERVENANT_TYPE_PRE_REGISTRATION
} from "../../../../shared-components/actions/localStorage/intervenantRegistration/intervenantRegistrationActionTypes"

/**
 * Part of the state for pre registration process
 * @param state
 * @param action
 * @returns {{}}
 */
const preRegistrationState = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_INTERVENANT_PRE_REGISTRATION_INFORMATIONS:
        case SAVE_INTERVENANT_TYPE_PRE_REGISTRATION:
            return action.intervenantInformations
        case CLEAR_INTERVENANT_REGISTRATION_INFORMATIONS:
            return {}
        default:
            return state
    }
}

export default preRegistrationState

/**
 * Return pre registration intervenant information
 * @param state
 */
export const getPreRegistrationState = state => state

/**
 * Return pre registration intervenant type
 * @param state
 */
export const getPreRegistrationIntervenantType = state => state.intervenantType
