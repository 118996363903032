import {REQUEST, REQUEST_SUCCESS, REQUEST_FAILURE} from "../../../shared-components/store/requests/requestsReducer"
import {RequestInfo, RequestKeys} from "../../../shared-components/constants/requests"

const bankAccount = (state = {}, action = {}) => {
    switch (action.type) {
        case REQUEST_SUCCESS:
            if (action.requestKeyName === RequestInfo[RequestKeys.RETRIEVE_BANK_ACCOUNT].name) {
                return action.bankAccount
            }
            return state
        case REQUEST_FAILURE:
        case REQUEST:
            if (action.requestKeyName === RequestInfo[RequestKeys.RETRIEVE_BANK_ACCOUNT].name) {
                return {}
            }
            return state
        default:
            return state
    }
}

export default bankAccount

export const getBankAccount = state => state
