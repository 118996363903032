import {combineReducers} from "redux"
import preRegistrationState, * as fromPreRegistrationState from "./preRegistration/preRegistrationSceneReducer"
import mainInformationsState, * as fromMainInformationsState from "./mainInformations/registrationMainInformationsReducer"
import refundInformationsState, * as fromRefundInformationsState from "./refundInformations/registrationRefundInformationsReducer"

/**
 * Part of the state for all registration process
 * @type {Reducer<any>}
 */
const registrationState = combineReducers({
    mainInformationsState,
    preRegistrationState,
    refundInformationsState
})

export default registrationState

/**
 * Return pre registration intervenant type
 * @param state
 */
export const getPreRegistrationIntervenantType =
    state => fromPreRegistrationState.getPreRegistrationIntervenantType(state.preRegistrationState)

/**
 * Return pre registration intervenant information
 * @param state
 */
export const getPreRegistrationState =
    state => fromPreRegistrationState.getPreRegistrationState(state.preRegistrationState)

/**
 * Return registration main informations state
 * @param state
 */
export const getRegistrationMainInformationsState =
    state => fromMainInformationsState.getMainInformationsState(state.mainInformationsState)

/**
 * Return registration main informations for overview scene of Physical Intervenant
 * @param state
 */
export const getOverviewPhysicalIntervenantMainInformationsState =
    state => fromMainInformationsState.getOverviewPhysicalIntervenantMainInformationsState(state.mainInformationsState)

/**
 * Return registration main informations for overview scene of Moral Intervenant
 * @param state
 */
export const getOverviewMoralIntervenantMainInformationsState =
    state => fromMainInformationsState.getOverviewMoralIntervenantMainInformationsState(state.mainInformationsState)


/**
 * Return registration refund informations state
 * @param state
 */
export const getRegistrationRefundInformationsState =
    state => fromRefundInformationsState.getRefundInformationsState(state.refundInformationsState)
