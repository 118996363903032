import {Page} from "./pages"

/**
 * Architecture of the pages of the application.
 * Structure :
 * - breadcrumbText : the text print in a breadcrumb
 * - route : the route to go to the page
 * - routeWithParamDesc : a second route, with param
 * - routeWithParam : a function take all params for the routeWithParamDesc and building a route
 * - parent : the parent page
 * - parentDisconnect : the parent page if no user is connected
 * - permissions: User permissions needed to access the page
 */
export const PagesArchitecture = {
    [Page.BARCODE_REFUND]: {
        breadcrumbText: "Remise par code barre",
        route: "/remise/code-barre",
        parent: Page.HOME,
        permissions: ["DEMANDE_REMBOUR_MASSE"],
        userConnected: true,
    },
    [Page.CONFIRM_BARCODE_REFUND]: {
        breadcrumbText: "Confirmation",
        route: "/remise/code-barre/confirmation",
        parent: Page.BARCODE_REFUND,
        permissions: ["DEMANDE_REMBOUR"],
        userConnected: true,
    },
    [Page.CONFIRM_SIMPLE_REFUND]: {
        breadcrumbText: "Confirmation",
        route: "/remise/simple/confirmation",
        parent: Page.SIMPLE_REFUND,
        permissions: ["DEMANDE_REMBOUR"],
        userConnected: true,
    },
    [Page.FILE_REFUND]: {
        breadcrumbText: "Remise par fichier",
        route: "/remise/fichier",
        parent: Page.HOME,
        permissions: ["DEMANDE_REMBOUR_FICHIER"],
        userConnected: true,
    },
    [Page.HISTORY_REFUND]: {
        breadcrumbText: "Historique de mes remises",
        route: "/historique/remises",
        parent: Page.HOME,
        permissions: ["HISTO_VIREMENTS"],
        userConnected: true,
    },
    [Page.HOME]: {
        breadcrumbText: "Accueil",
        route: "/accueil",
        userConnected: true,
    },
    [Page.LEGAL_NOTICE]: {
        breadcrumbText: "Mentions Légales",
        parent: Page.HOME,
        parentDisconnect: Page.LOGIN,
        route: "/mentions-legales",
    },
    [Page.RGPD]: {
        parentDisconnect: Page.LOGIN,
        parent: Page.HOME,
        breadcrumbText: "Données personnelles",
        route: "/rgpd",
    },
    [Page.LOGIN]: {
        breadcrumbText: "Connexion",
        route: "/login",
    },
    [Page.SIMPLE_REFUND]: {
        breadcrumbText: "Remise simple",
        route: "/remise/simple",
        parent: Page.HOME,
        permissions: ["DEMANDE_REMBOUR"],
        userConnected: true,
    },
    [Page.NOT_FOUND]: {
        breadcrumbText: "Page introuvable",
        route: "/notfound",
    },
    [Page.PAYMENT_HISTORY]: {
        breadcrumbText: "Comptes CESU",
        route: "/historique/comptes-cesu",
        parent: Page.HOME,
        permissions: ["HISTO_VIREMENTS"],
        userConnected: true,
    },
    [Page.PROFILE_AUTHENTICATION_INFORMATIONS]: {
        breadcrumbText: "Données personnelles",
        route: "/profil/infos-connexion",
        parent: Page.HOME,
        permissions: ["FICHE_CLIENT"],
        userConnected: true,
    },
    [Page.PROFILE_MAIN_INFORMATIONS]: {
        breadcrumbText: "Données personnelles",
        route: "/profil/infos-principales",
        parent: Page.HOME,
        permissions: ["FICHE_CLIENT"],
        userConnected: true,
    },
    [Page.PROFILE_REFUND_INFORMATIONS]: {
        breadcrumbText: "Données personnelles",
        route: "/profil/infos-remboursement",
        parent: Page.HOME,
        permissions: ["FICHE_CLIENT"],
        userConnected: true,
    },
    [Page.REGISTRATION]: {
        breadcrumbText: "Inscription",
        route: "/inscription",
    },
    [Page.REGISTRATION_CONFIRMATION]: {
        breadcrumbText: "Inscription terminée",
        route: "/inscription-confirmation",
    },
    [Page.REGISTRATION_MAIN_INFORMATIONS]: {
        breadcrumbText: "Formulaire d'affiliation",
        route: "/inscription/infos-principales",
        parent: Page.REGISTRATION,
    },
    [Page.REGISTRATION_OVERVIEW_INFORMATIONS]: {
        breadcrumbText: "Formulaire d'affiliation",
        route: "/inscription/infos-recap",
        parent: Page.REGISTRATION,
    },
    [Page.REGISTRATION_REFUND_INFORMATIONS]: {
        breadcrumbText: "Formulaire d'affiliation",
        route: "/inscription/infos-remboursement",
        parent: Page.REGISTRATION,
    },
    [Page.TRANSFER_HISTORY]: {
        breadcrumbText: "Mes remboursements",
        route: "/historique/virements",
        parent: Page.HOME,
        permissions: ["HISTO_VIREMENTS"],
        userConnected: true,
    },
    [Page.RECOVER_LOGIN_PASSWORD]: {
        breadcrumbText: "Mot de passe oublié",
        route: "/recoverLoginPassword",
    },
    [Page.RESET_PASSWORD]: {
        breadcrumbText: "Réinitialiser son mot de passe",
        routeWithParamDesc: "/resetPassword/:token",
        routeWithParam: token => "/resetPassword/" + token,
    },
    [Page.TECHNICAL_ERROR]: {
        route: "/erreur-technique",
    },
    [Page.UNAUTHORIZED]: {
        breadcrumbText: "Accès interdit",
        route: "/unauthorized",
        parent: Page.HOME,
    },
    [Page.VALIDATE_BARCODE_REFUND]: {
        breadcrumbText: "Valider mes remises",
        route: "/remise/code-barre/validation",
        parent: Page.BARCODE_REFUND,
        permissions: ["DEMANDE_REMBOUR"],
        userConnected: true,
    },
    [Page.VALIDATE_SIMPLE_REFUND]: {
        breadcrumbText: "Valider mes remises",
        route: "/remise/simple/bvalidation",
        parent: Page.SIMPLE_REFUND,
        permissions: ["DEMANDE_REMBOUR"],
        userConnected: true,
    },
}
