import {includes} from 'lodash'

/**
 * The type of intervenant.
 */
export const IntervenantType = {
    INTERVENANT_DIRECT: "INTERVENANT_DIRECT",
    MANDATAIRE: "MANDATAIRE",
    PRESTATAIRE: "PRESTATAIRE",
    PROSPECT_MORAL: "PROSPECT_MORAL",
    PROSPECT_PHYSIQUE: "PROSPECT_PHYSIQUE"
}

/**
 * Describe a physical intervenant
 * @type {String}
 */
export const PHYSICAL_INTERVENANT = "PHYSICAL_INTERVENANT"
/**
 * Describe a moral intervenant
 * @type {String}
 */
export const MORAL_INTERVENANT = "MORAL_INTERVENANT"


export const PhysicalIntervenants = [
    IntervenantType.INTERVENANT_DIRECT,
    IntervenantType.PROSPECT_PHYSIQUE
]

export const MoralIntervenants = [
    IntervenantType.MANDATAIRE,
    IntervenantType.PRESTATAIRE,
    IntervenantType.PROSPECT_MORAL
]

export const convertIntervenantType = intervenantType => {

    if (includes(PhysicalIntervenants, intervenantType)) {
        return PHYSICAL_INTERVENANT
    }

    if (includes(MoralIntervenants, intervenantType)) {
        return MORAL_INTERVENANT
    }

    return null
}
