import {combineReducers} from "redux"
import user, * as fromUser from "../../shared-components/store/sessionStorage/user/userReducer"

const sessionStorage = combineReducers({
    user,
})

export default sessionStorage

// USER OPERATIONS
/**
 * Get the user token from the store.
 */
export const getUserToken = state => fromUser.getUserToken(state.user)
/**
 * Get the expiration date.
 */
export const getExpirationDate = state => fromUser.getExpirationDate(state.user)
/**
 * Get user permissions from the store.
 */
export const getUserPermissions = state => fromUser.getUserPermissions(state.user)
/**
 * Get user identity from the store.
 */
export const getUserDetails = state => fromUser.getUserDetails(state.user)
