import {
    SET_ID_DETAILS_REFUND,
    CLEAR_DETAILS_REFUND_ID,
} from "../../../actions/temporaries/detailsRefund/detailsRefundActionTypes"

const detailsRefund = (state = {}, action = {}) => {
    switch (action.type) {
        case SET_ID_DETAILS_REFUND:
            return {
                id: action.id,
            }
        case CLEAR_DETAILS_REFUND_ID:
            return {}
        default:
            return state
    }
}

export default detailsRefund

export const getId = state => state.id
