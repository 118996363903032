import {
    CLEAR_REGISTRATION_REFUND_INFORMATIONS,
    SAVE_REGISTRATION_REFUND_INFORMATIONS
} from "../../../../actions/localStorage/registrationRefundInformations/registrationRefundInformationsActionTypes"
import {CLEAR_INTERVENANT_REGISTRATION_INFORMATIONS} from "../../../../shared-components/actions/localStorage/intervenantRegistration/intervenantRegistrationActionTypes"

/**
 * Part of the state for registration refund information process
 * @param state
 * @param action
 * @returns {{}}
 */
const refundInformationsState = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_REGISTRATION_REFUND_INFORMATIONS:
            return action.registrationRefundInformations
        case CLEAR_INTERVENANT_REGISTRATION_INFORMATIONS:
        case CLEAR_REGISTRATION_REFUND_INFORMATIONS:
            return {}
        default:
            return state
    }
}

export default refundInformationsState

/**
 * Return registration refund informations
 * @param state
 */
export const getRefundInformationsState = state => state
